<template flex>
  <div class="login d-flex justify-center">
    <v-main class="d-flex">
      <v-layout fluid flex align-center justify-center>
        <v-flex xs12 sm12 elevation-0>
          <v-card flat class="pa-16">
            <v-layout class="flex-column">
              <h4 class="display-1 font-weight-medium pb-2">Charge Bee POC</h4>
              <p>To show that plan for a subscription can be changed</p>
            </v-layout>
            <v-card-text class="py-4 px-0">
              <v-form v-model="valid" ref="form">

                  <v-data-table
                    :headers="customerHeaders"
                    :items="customers"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    item-key="customer.id"
                    show-expand
                    class="elevation-1"
                    @click:row="custClicked"
                  >
                    <template v-slot:expanded-item="{ headers, item }">
                      <v-card
                          color="grey lighten-4"
                          elevation="0"
                          class="pa-4 d-flex my-5 rounded-lg"
                      >
                      <td :colspan="headers.length" :key="updateKey">
                        Subscribed to plan : {{ item.plan }}
                        <br>
                        <br>
                        <v-text-field
                          label="Enter Total Usage"
                          placeholder="Enter Total Usage"
                          v-model="usage"
                          required
                          outlined
                          dense
                        ></v-text-field>
                        <v-layout justify-end flex-wrap>
                        <v-btn
                          block
                          depressed
                          large
                          class="primary white--text mb-7"
                          @click="process(item.subId)"
                          >Process</v-btn
                        >
                        </v-layout>
                      </td>
                      </v-card>
                    </template>
                  </v-data-table>

              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-main>
  </div>
</template>

<script>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import { CB_Customers, CB_Subs, CB_ChangeSub } from '../utils/apptweakqueries';
  export default {
    data () {
      return {
        valid : true,
        usage : null,
        expanded: [],
        updateKey: 0,
        singleExpand: true, //must be false if switch is enabled
        customerHeaders: [
          {
            text: 'Customer Id',
            align: 'start',
            sortable: false,
            value: 'customer.id',
          },
          { text: 'First Name', value: 'customer.first_name' },
          { text: 'Last Name', value: 'customer.last_name' },
          { text: '', value: 'data-table-expand' },
        ],
        customers : []
      }
    },
    methods: {
      process(subId) {
        console.log('process clicked', subId, this.usage);
        //return;
        this.$apollo
        .query({
          query: CB_ChangeSub,
          variables: {
            subId:subId,
          }
        })
        .then(data => {
          console.log(data.data);
          if(data.data.changePlanForSub){
            alert('Bill Generated but unbilled. This Amount will be added to the next invoice! Refresh and check plan');
          }else{
            alert('Bill generation failed! ');
          }
        })
        .catch(error => {
          console.log(error);
          alert('Bill generation failed! ');
        });
      },

      custClicked(item){
        console.log('Cust clicked', item.customer.id);
        const index = this.expanded.indexOf(item)
        if (index === -1) {
          this.expanded.push(item)
        } else {
          this.expanded.splice(index, 1)
        }
        if(!item.plan){
          this.getCustSub(item.customer.id);
        }
      },

      getCustSub(custId){
        this.$apollo
        .query({
          query: CB_Subs,
          variables: {
            custId: custId
          }
        })
        .then(data => {
          console.log(data.data);
          //this.customers = data.data.getSubsForCust.list;
          for (let index = 0; index < this.customers.length; index++) {
            const element = this.customers[index];
            if(element.customer.id === data.data.getSubsForCust.list[0].subscription.customer_id){
              element['plan'] = data.data.getSubsForCust.list[0].subscription.plan_id;
              element['subId'] = data.data.getSubsForCust.list[0].subscription.id;
            }
          }
          console.log(this.customers);
          this.updateKey+= 1;
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    beforeMount(){
      this.$apollo
      .query({
        query: CB_Customers,
        variables: {
          id:'all'
        }
      })
      .then(data => {
        console.log(data.data);
        this.customers = data.data.getAllCBCustomers.list;
      })
      .catch(error => {
        console.log(error);
      });
    }
  }
</script>
